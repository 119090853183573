<template>
  <v-dialog v-model="show" scrollable persistent max-width="1000px">
    <v-card>
      <v-card-title style="font-size: 1.2rem" class="pa-1 pl-3"
        >PUBLICATION DETAILS</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text style="height: 900px" class="pa-4">
        <v-form ref="formStep2" v-model="formStep2">
          <div class="cardHeader">Publication Detail</div>
          <v-card flat class="pa-4">
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  outlined
                  dense
                  counter
                  maxlength="25"
                  v-model="appData.mediaName"
                  label="Name of Media House *"
                  :rules="[rules.req]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="appData.mwdiaWebsite"
                  label="Publication Website"
                ></v-text-field>
                <!-- :rules="url" -->
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  label="Media Category"
                  outlined
                  dense
                  hide-details
                  v-model="appData.mediaType"
                  :items="mediaTypeList"
                  item-text="mc_name"
                  item-value="mc_id"
                  @change="changeCategory"
                  :rules="[rules.req]"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  label="Type of Organisation"
                  outlined
                  dense
                  hide-details
                  v-model="appData.organisationType"
                  :items="subCategoryList"
                  item-text="msc_name"
                  item-value="msc_id"
                  :rules="[rules.req]"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-card>
          <div class="cardHeader">Publication Address</div>
          <v-card flat class="pa-4">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  hide-details
                  dense
                  label="Line 1 *"
                  :rules="[rules.req]"
                  v-model="appData.pAddLine1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  hide-details
                  dense
                  label="Line 2"
                  v-model="appData.pAddLine2"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="appData.pCity"
                  hide-details
                  outlined
                  dense
                  label="Town/City *"
                  :rules="[rules.req]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="appData.pState"
                  hide-details
                  outlined
                  dense
                  label="Parish/State/County *"
                  :rules="[rules.req]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="appData.pCountry"
                  hide-details
                  outlined
                  dense
                  label="Country *"
                  :items="countries"
                  :rules="[rules.req]"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="appData.pZipCode"
                  hide-details
                  outlined
                  dense
                  label="Postal/Zip Code  *"
                  :rules="[rules.req]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
          <div class="cardHeader">Authorisation Details</div>
          <div class="pa-2 pt-4" style="font-size: 1rem">
            Please be aware that you cannot be the assigning editor for your own
            application. You must provide details of your employer or editor in
            this section.
          </div>
          <v-card flat class="pa-4">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  hide-details
                  dense
                  label="Name *"
                  :rules="[rules.req]"
                  v-model="appData.authName"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  hide-details
                  dense
                  label="Designation *"
                  v-model="appData.authRole"
                  :rules="[rules.req]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <!-- <v-col cols="12" md="6">
                    <vue-tel-input-vuetify
                      v-model="appData.authMobile"
                      outlined
                      dense
                      label="Mobile *"
                      :rules="[rules.req]"
                    ></vue-tel-input-vuetify>
                  </v-col> -->

              <v-col cols="12" md="2">
                <v-select
                  v-model="appData.authMobExt"
                  hide-details
                  outlined
                  dense
                  label="Country Code *"
                  :items="countriescode"
                  item-text="code"
                  item-value="dial_code"
                  :rules="[rules.req]"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="appData.authMobile"
                  outlined
                  dense
                  label="Mobile *"
                  maxlength="15"
                  :rules="Vmobilenumber"
                  @keypress="filterNum(event)"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="appData.authEmail"
                  hide-details
                  outlined
                  dense
                  label="Email Id *"
                  :rules="[rules.req, rules.email]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          large
          class="pl-5 pr-5 ma-2"
          color="error"
          @close="show = false"
          @click="btncancel"
        >
          <v-icon left> mdi-close-thick </v-icon>CLOSE</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          large
          class="pl-5 pr-5"
          :disabled="!formStep2"
          color="success"
          @click="save"
        >
          <v-icon left> mdi-content-save </v-icon>
          SAVE</v-btn
        >
      </v-card-actions>
    </v-card>

    <v-snackbar
      top
      centered
      v-model="snackbar.display"
      :color="snackbar.color"
      >{{ snackbar.text }}</v-snackbar
    >
    <v-overlay :value="isLoading" style="z-index: 9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
//import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
import nationality from "../../../data/nationality.json";
import countrycode from "../../../data/countryCode.json";
import country from "../../../data/country.json";
export default {
  components: {},
  props: ["fData", "show"],

  //  props: {
  //     fData: {
  //       type: Object,
  //     },
  //     show: {
  //       type: Boolean,
  //     },
  //   },

  data: () => ({
    snackbar: { display: false, text: "", color: "primary" },
    isLoading: false,
    formStep2: false,
    dob_max: "",
    menu: false,
    stepLoading: false,
    countries: [],
    nationality: [],
    countriescode: [],
    appData: {
      application_id: "",
      event_id: "",
      mediaName: "",
      mwdiaWebsite: "",
      mediaType: "",
      roleForEvent: "",
      organisationType: "",
      pAddLine1: "",
      pAddLine2: "",
      pCity: "",
      pState: "",
      pCountry: "",
      pZipCode: "",
      authName: "",
      authRole: "",
      authMobile: "",
      authEmail: "",
      authMobExt: "",
    },
    selMediaCategory: {},
    mediaTypeList: [],
    subCategoryList: [],

    url: [
      (v) => !!v || "URL is required",
      // v => (v && v.length <= 256) || 'URL must be less than 256 characters', /* eslint-disable-next-line no-useless-escape*/
      // v => /https?:[0-9]*\/\/[\w!?/\+\-_~=;\.,*&@#$%\(\)\'\[\]]+/.test(v) || 'URL must be valid',
    ],

    rules: {
      req: (val) => (val !== null && val !== "") || "Required!",
      email: (v) =>
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
      reqArr: (val) => val.length > 0 || "Required!",
      reqwebsite: (val) =>
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(
          val
        ) || "Website must be valid",
    },

    Vmobilenumber: [
      (v) => !!v || "Required",
      (v) =>
        (!isNaN(parseFloat(v)) && v >= 1 && v <= 99999999999999) ||
        "15 Number Only",
    ],
  }),

  mounted() {
    this.countries = country;
    this.nationality = nationality;
    this.countriescode = countrycode;
    //console.log(this.fData.firstName);

    // this.$axios.get("/media/mediacategory").then((res) => {
    //   this.mediaTypeList = res.data.result;
    // });

    this.$axios
      .get("/media/mediaCategoryByEid/" + this.fData.event_id)
      .then((res) => {
        this.mediaTypeList = res.data.result;
      });

    this.binddata();
    this.LoadCategory(this.appData.mediaType);
  },

  methods: {
    filterNum: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^\d+$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    savedob(date) {
      this.$refs.menu.save(date);
    },

    showSnackbar: function (scolor, stext) {
      this.snackbar.display = true;
      this.snackbar.text = stext;
      this.snackbar.color = scolor;
    },

    binddata: function () {
      this.appData.application_id = this.fData.application_id;
      this.appData.event_id = this.fData.event_id;
      this.appData.mediaName = this.fData.mediaName;
      this.appData.mwdiaWebsite = this.fData.mwdiaWebsite;
      this.appData.pAddLine1 = this.fData.pAddLine1;
      this.appData.pAddLine2 = this.fData.pAddLine2;
      this.appData.pCity = this.fData.pCity;
      this.appData.pState = this.fData.pState;
      this.appData.pCountry = this.fData.pCountry;
      this.appData.pZipCode = this.fData.pZipCode;
      this.appData.authName = this.fData.authName;
      this.appData.authRole = this.fData.authRole;
      this.appData.authMobile = this.fData.authMobile;
      this.appData.authEmail = this.fData.authEmail;
      this.appData.mediaType = this.fData.mediaType;
      this.appData.organisationType = this.fData.organisationType;
      this.appData.authMobExt = this.fData.authMobExt;
      this.appData.roleForEvent = this.fData.roleForEvent;
    },

    save: async function () {
      if (this.appData.mediaType == 0) {
        this.showSnackbar("error", "Please Update Media Category!");
        return;
      }

      if (this.fData.email == this.appData.authEmail) {
        this.showSnackbar("error", "Email ID not to be same !");
        return;
      }
      this.isLoading = true;
      console.log("check for publications");
      console.log(this.appData);
      await this.$axios
        .put("Media/applicationPublication", this.appData)
        .then((res) => {
          //console.log(res.data);
          if (res.data.status == "Success") {
            this.$emit("hidePublicationDilog", { open: false, refresh: true });
            this.showSnackbar("success", "Updated Successfully!");
            // this.bindmediadata();
          } else if (res.data.status == "Failed") {
            if (res.data.message == "ALREADYEXISTS") {
              this.showSnackbar("error", "Applicant already registered!");
            } else {
              this.showSnackbar("error", "Something went wrong!");
            }
          } else {
            this.showSnackbar("error", "Something went wrong!");
          }
          this.isLoading = false;
        });
    },

    btncancel: function () {
      //this.show=false;
      this.$emit("hidePublicationDilog", { open: false, refresh: false });
      this.binddata();
    },

    // bindmediadata:function(){
    //    this.$axios
    //     .get("/media/mediarolebycategory/" + this.fData.mediaType)
    //     .then((res) => {
    //       this.mediaRole = res.data.result;
    //     })
    //     .catch();
    // },

    changeCategory: async function (val) {
      //alert(val);
      await this.$axios
        .get("/media/mediasubcategory/" + val)
        .then((res) => {
          this.subCategoryList = res.data.result;
        })
        .catch();
    },

    LoadCategory: async function (val) {
      //alert(val);
      await this.$axios
        .get("/media/mediasubcategory/" + val)
        .then((res) => {
          this.subCategoryList = res.data.result;
        })
        .catch();
    },
  },
};
</script>

<style>
</style>